<template>
  <b-nav-item-dropdown
    v-if="userData!==null"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div
        class="d-sm-flex d-none user-nav"
      >
        <p class="user-name font-weight-bolder mb-0 text-primary">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="ptImage"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'member-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'switch-account'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UsersIcon"
        class="mr-50"
      />
      <span>Member</span>
    </b-dropdown-item>

    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
      :href="url"
      target="_blank"
      @click="getCheckupBtn"
    >
      <feather-icon
        size="16"
        icon="HeartIcon"
        class="mr-50"
      />
      <span class="text-wrap">Checkup Report</span>

    </b-dropdown-item> -->
    <b-dropdown-divider />
    <!-- <b-dropdown-item
      :to="{ name: 'member-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Sign Out</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
// import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      idcard: '',
      url: '',
      interval: null,
      // ptImage: '',
      // avatarText,
    }
  },
  created() {
    // console.log('UserDropdown', this.userData)
    if (this.userData !== null) {
      this.getProfileImage()
    }
    // this.getCheckupBtn()
  },
  methods: {
    getProfileImage() {
      if (localStorage.getItem('patientImage')) {
        this.ptImage = `data:image/jpeg;base64, ${localStorage.getItem('patientImage')}`
      } else {
        this.ptImage = this.userData.avatar
      }
    },
    getCheckupBtn() {
      if (localStorage.getItem('patientView')) {
        this.idcard = localStorage.getItem('patientView')
      } if (localStorage.getItem('patientTempData')) {
        this.idcard = JSON.parse(localStorage.getItem('patientTempData')).idCard
      }
      this.$http({
        // url: `http://192.168.100.56/api/Patient/UrlByIDCard/${this.idcard}`,
        url: `http://phrapi.sriphat.com/api/Patient/UrlByIDCard/${this.idcard}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.url = data
      }).catch(error => {
        console.log(error)
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Remove patientData from localStorage
      localStorage.removeItem('patientData')
      localStorage.removeItem('patientTempData')
      localStorage.removeItem('patientImage')

      // Remove accountData from LocalStorage
      localStorage.removeItem('accountData')

      // Remove all data from localStorage
      localStorage.clear()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
